@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500&display=swap');

.feedPage {
  background-image: linear-gradient(hsl(181, 83%, 21%), hsl(182, 87%, 5%));
  background-repeat: no-repeat; 
  background-size: cover; 
  padding-top: 1px;
  min-height: 110vh;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
}

.city-selector-container {
  display: flex;
  padding: 30px;
  margin-top: 20px;
  font-size: 25px;
  font-weight: 500;
  border-radius: 25px;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.3);
  color: hsl(182, 87%, 21%);
  background: rgba(245, 245, 245, 0.586);
  font-family: 'Montserrat', sans-serif;
  flex-direction: column;
}

#city-selector {
  font-size: 25px;
  font-family: 'Montserrat', sans-serif;
}

#current-location {
  font-family: 'Montserrat', sans-serif;
  display: block;
  margin-top: 1rem;
  text-align: center;
  font-size: 18px;
  color: hsl(182, 87%, 21%);
}

.enter-city-div {
  flex-direction: r;
}

.city-selector-button {
  -webkit-font-smoothing: antialiased;
  margin: 5px;
  border: 2px solid whitesmoke;
  border-radius: 10px;
  padding: 10px;
  font-size: 15px;
  color: whitesmoke;
  transition: 0.5s ease-in-out;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  width: 100px;
  font-family: 'Montserrat', sans-serif;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.city-selector-button:hover {
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.city-selector-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: rgba(0, 0, 0, 0.2);
}

.city-selector-input {
  border-radius: 5px;
  border: 0;
  height: 40px;
  margin-top: 5px;
  margin-right: 5px;
}
